/* eslint-disable */
// To-do: fix all linter warnings
import { useState, useEffect } from 'react';
import { query_data, query_creator_chatlogs } from '../../utils/helper';
import { useAuth } from '../../hooks/useAuth';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the styles
import 'react-date-range/dist/theme/default.css'; // Import the theme
import './dashboard.css';
import {
  Grid,
  Typography,
  Button,
  Divider,
  Tab,
  Tabs,
  Avatar,
  TextareaAutosize
} from '@mui/material';
import Box from '@mui/material/Box';
import { updateMessagesData, removeHTML, findAndReplaceLinks } from '../../utils/helper';
import { editedMessageResponse } from '../../utils/api';

import { MathJax } from 'better-react-mathjax';
import ReactMarkdown from 'react-markdown';
import CircularProgress from '@mui/material/CircularProgress';

export default function CreatorDashboard() {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedQuestion, setEditedQuestion] = useState('');
  const [editedMessage, setEditedMessage] = useState('');
  const [originalMessage, setOriginalMessage] = useState('');
  const [chatFeedFilter, setChatFeedFilter] = useState('All');

  const [showCalendar, setShowCalendar] = useState(false);

  // const formatDate = (date) => new Date(date).toLocaleDateString('en-US');
  const now = new Date();
  const currentDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

  const previousDay = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

  const [dateRange, setDateRange] = useState([
    {
      startDate: previousDay,
      endDate: currentDay,
      key: 'selection',
    },
  ]);

  const [chartData, setChartData] = useState([
    {
      key: 'Like',
      count: 0,
    },
    {
      key: 'Dislike',
      count: 0,
    },
  ]);

  const [dateRange2, setDateRange2] = useState([
    {
      startDate: previousDay,
      endDate: currentDay,
      key: 'selection',
    },
  ]);

  const [dateRangePreview, setDateRangePreview] = useState([
    {
      startDate: previousDay,
      endDate: currentDay,
      key: 'selection',
    },
  ]);

  const [dateRangePreview2, setDateRangePreview2] = useState([
    {
      startDate: previousDay,
      endDate: currentDay,
      key: 'selection',
    },
  ]);

  const [totalNewFans, setTotalNewFans] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalReturning, setTotalReturning] = useState(0);
  const [senderData, setSenderData] = useState(null)
  const [newfans, setNewFans] = useState([]);
  const [messages_count, setMessagesCount] = useState([]);
  const [emails, setEmailList] = useState([]);
  const [countLik, setCountLik] = useState({});
  const [chatfeed, setChatFeed] = useState([]);
  const [likes_chatfeed, setLikesChatFeed] = useState([]);
  const [dislikes_chatfeed, setDislikesChatFeed] = useState([]);
  const [neither_chatfeed, setNeitherChatFeed] = useState([]);
  const [returning, setReturning] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [selectedRows2, setSelectedRows2] = useState(new Set());
  const { authUser } = useAuth();
  const [list_type, setListType] = useState([]);
  const [totalFans, setTotalFans] = useState(0);
  const [totalMessagesSentAndReceived, setTotalMessagesSentAndReceived] =
    useState(0);

  const condition = authUser['userType'] === 'creator';
  const creatorID = authUser['uid'];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let totalFans = 0;
    let totalMessagesSentAndReceived = 0;
    let totalReturningUsers = 0;

    query_creator_chatlogs(creatorID)
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (element['direction'] === 'incoming') {
            totalMessagesSentAndReceived++;
          }
          setTotalMessagesSentAndReceived(totalMessagesSentAndReceived);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    query_data(null, null, creatorID)
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          totalFans++;
        }
        setTotalFans(totalFans);
      })
      .catch((error) => {
        console.log(error);
      });

    let fans_list = [];
    let new_fans = {
      date: '',
      fans: 0,
    };
    let hashmap_day = {};

    let email_list = [];
    let email = {
      userName: '',
      email: '',
      dateCreated: '',
    };

    let messages_list = [];
    let total_messages = {
      date: '',
      messages: 0,
    };
    let hashmap_messages = {};

    let chatlogs = {
      id: '',
      image: '',
      username: '',
      fullname: '',
      question: undefined,
      message: undefined,
      editedMessage: undefined,
      feedback: '',
      sent: '',
      likeDislike: '',
      sentDate:'',
      sentTime:''
    };
    let chatlogs_list = [];

    let countLikeDislike = {
      like: 0,
      dislike: 0,
    };

    let hashmap_returning = {};
    let hashmap_returning_day = {};
    let returning_list = [];
    let returning = {
      date: '',
      returning: 0,
    };

    let newFansCount = 0;
    let totalMessagesCount = 0;
    let totalReturningCount = 0;

    query_creator_chatlogs(creatorID)
      .then((data) => {
        // console.log(data);
        data = data.filter(
          (item) =>
            ((item['sentTime'] &&
              new Date(
                item['sentTime'].seconds * 1000 +
                  item['sentTime'].nanoseconds / 1000
              ).getTime() >= dateRange[0].startDate.getTime()) ||
              (item['oldSentTime'] &&
                new Date(item['oldSentTime']).getTime() >=
                  dateRange[0].startDate.getTime())) &&
            ((item['sentTime'] &&
              new Date(
                item['sentTime'].seconds * 1000 +
                  item['sentTime'].nanoseconds / 1000
              ).getTime() <= dateRange[0].endDate.getTime()) ||
              (item['oldSentTime'] &&
                new Date(item['oldSentTime']).getTime() <=
                  dateRange[0].endDate.getTime()))
        );

        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          let createdAtTimestamp;
          let datetimeString = new Date(createdAtTimestamp).toLocaleDateString(
            'en-US',
            { month: 'short', day: 'numeric', year: 'numeric' }
          );
          if (datetimeString === 'Invalid Date') {
            datetimeString = new Date(element['sentTime']).toLocaleDateString(
              'en-US',
              { month: 'short', day: 'numeric', year: 'numeric' }
            );
          }
          hashmap_messages[datetimeString] =
            hashmap_messages[datetimeString] + 1 || 1;

          if (
            element['direction'] === 'outgoing' &&
            element['userType'] === 'bot'
          ) {
            if (!hashmap_returning[element['senderID']]) {
              hashmap_returning[element['senderID']] = new Set([
                datetimeString,
              ]);
            }
            hashmap_returning[element['senderID']].add(datetimeString);

            if (hashmap_returning[element['senderID']].size >= 2) {
              hashmap_returning_day[datetimeString] =
                hashmap_returning_day[datetimeString] + 1 || 1;
            }
          }
          if (typeof element['oldSentTime'] === 'string') {
            createdAtTimestamp = new Date(element['oldSentTime']).getTime();
          } else {
            createdAtTimestamp =
              element['sentTime'].seconds * 1000 +
              element['sentTime'].nanoseconds / 1000000;
          }

          if (element['direction'] === 'incoming') {

            chatlogs.message = element['message'];
            chatlogs.feedback = element['feedback'];

            // Extract and format date and time separately
            let date = new Date(createdAtTimestamp);
            chatlogs.sentDate = date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
            chatlogs.sentTime = date.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              //second: '2-digit',
            });

            if (datetimeString === 'Invalid Date') {
              datetimeString = new Date(element['sentTime']).toLocaleString(
                'en-US',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                }
              );
            }

            chatlogs.sent = datetimeString;
            if (element['likeDislike'] === 0) {
              chatlogs.likeDislike = '';
            } else if (element['likeDislike'] === 1) {
              countLikeDislike.like += 1;
              chatlogs.likeDislike = 'Like';
            } else if (element['likeDislike'] === 2) {
              chatlogs.likeDislike = 'Dislike';
              countLikeDislike.dislike += 1;
            }

            if (element['editedMessage'] != undefined) {
              chatlogs.editedMessage = element['editedMessage'];
            }
            chatlogs.id = element['id'];
          } else if (element['direction'] === 'outgoing') {
            chatlogs.question = element['message'];
            chatlogs.username = element['username']
            chatlogs.fullname = element['fullName']
            chatlogs.image = element['profilePicUrl']
          }

          if (chatlogs.question && !chatlogs.message) {
            chatlogs.question = undefined;
          }

          if (chatlogs.question && chatlogs.message) {
            chatlogs_list.push(chatlogs);
            chatlogs = {
              id: '',
              image: '',
              username: '',
              fullname: '',
              question: undefined,
              message: undefined,
              editedMessage: undefined,
              feedback: '',
              sent: '',
              likeDislike: '',
              sentDate:'',
              sentTime:''
            };
          }


        }

        Object.entries(hashmap_returning_day).forEach(([key, value]) => {
          returning.date = key;
          returning.returning = value as number;
          totalReturningCount += value as number;
          returning_list.push(returning);
          returning = {
            date: '',
            returning: 0,
          };
        });

        Object.entries(hashmap_messages).forEach(([key, value]) => {
          total_messages.date = key;
          total_messages.messages = value as number;
          totalMessagesCount += value as number;
          messages_list.push(total_messages);
          total_messages = {
            date: '',
            messages: 0,
          };
        });

        returning_list.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
        messages_list.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
        chatlogs_list.sort(
          (a, b) => new Date(b.sent).getTime() - new Date(a.sent).getTime()
        );

        // console.log(chatlogs_list);

        setReturning(returning_list);
        setMessagesCount(messages_list);
        setCountLik(countLikeDislike);
        let chart = Object.keys(countLikeDislike).map((key) => ({
          key: key,
          count: countLikeDislike[key],
        }));

        setChartData(chart);
        setChatFeed(chatlogs_list);
        setLikesChatFeed(
          chatlogs_list.filter((item) => item.likeDislike === 'Like')
        );
        setDislikesChatFeed(
          chatlogs_list.filter((item) => item.likeDislike === 'Dislike')
        );
        setNeitherChatFeed(
          chatlogs_list.filter((item) => item.likeDislike === '')
        );

        setTotalReturning(totalReturningCount);
        setTotalMessages(totalMessagesCount);
      })
      .catch((error) => {
        console.log(error);
      });

    query_data(dateRange2[0].startDate, dateRange2[0].endDate, creatorID)
      .then((data) => {
        // console.log(data);
        for (const element of data) {
          email.userName = element['userName'];
          email.email = element['email'];
          email.dateCreated = element['createdAt']
            .toDate()
            .toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            });
          email_list.push(email);
          email = {
            userName: '',
            email: '',
            dateCreated: '',
          };
          if (
            hashmap_day.hasOwnProperty(
              element['createdAt'].toDate().toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })
            )
          ) {
            hashmap_day[
              element['createdAt'].toDate().toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })
            ] += 1;
          } else {
            hashmap_day[
              element['createdAt'].toDate().toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })
            ] = 1;
          }
        }
        Object.entries(hashmap_day).forEach(([key, value]) => {
          new_fans.date = key;
          new_fans.fans = value as number;
          newFansCount += value as number;
          fans_list.push(new_fans);
          new_fans = {
            date: '',
            fans: 0,
          };
        });
        setNewFans(fans_list);
        setEmailList(email_list);
        setTotalNewFans(newFansCount);
        // console.log(fans_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dateRange, dateRange2, authUser]);


  const errorAccess = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div style={{ maxWidth: '600px', width: '100%' }}>
          <h1>Only Creators can access this page</h1>
        </div>
      </div>
    );
  };

  const handleRowClick = (index) => {
    const updatedSelection = new Set(selectedRows);
    if (updatedSelection.has(index)) {
      updatedSelection.delete(index);
    } else {
      updatedSelection.add(index);
    }
    setSelectedRows(updatedSelection);
  };

  const handleRowClick2 = (index) => {
    const updatedSelection = new Set(selectedRows2);
    if (updatedSelection.has(index)) {
      updatedSelection.delete(index);
    } else {
      updatedSelection.add(index);
    }
    setSelectedRows2(updatedSelection);
  };

  const handleEditClick = (index: number, message: string, question: string) => {
    setEditingIndex(index);
    setEditedMessage(removeHTML(message));
    setOriginalMessage(removeHTML(message));
    setEditedQuestion(question);
  };

  const handleSaveClick = async (index) => {
    if (editedMessage !== originalMessage) {
      let updatedMessageData = {
        question: editedQuestion,
        editedMessage: findAndReplaceLinks(editedMessage),
        is_indexed: false,
      };
      chatfeed[index].editedMessage = updatedMessageData.editedMessage;
      await updateMessagesData(chatfeed[index].id, updatedMessageData);
      editedMessageResponse({message_id: chatfeed[index].id});
    }
    // update_creator_chatlogs(chatfeed[index].id, editedMessage);
    // console.log(chatfeed[index].id);
    setEditingIndex(-1);
    setEditedMessage('');
    setOriginalMessage('');
    setEditedQuestion('');
  };

  const handleCancelClick = (index) => {
    setEditingIndex(-1);
    setEditedMessage('');
    setOriginalMessage('');
    setEditedQuestion('');
  };

  // Handler to update the filter selection
  const handleFilterChange = (event, newValue) => {
    setChatFeedFilter(newValue);
  };


  // Filter the chat feed based on the selected option
  const filteredChatFeed = () => {
    if (chatFeedFilter === 'Likes') {
      return likes_chatfeed;
    } else if (chatFeedFilter === 'Dislikes') {
      return dislikes_chatfeed;
    } else if (chatFeedFilter === 'Neither') {
      return neither_chatfeed;
    } else {
      return chatfeed;
    }
  };


    const [selectedDateRangeTab, setSelectedDateRangeTab] = useState('thisMonth');

  const getEndOfDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  };


  useEffect(() => {

    switch (selectedDateRangeTab) {
      case 'today':
        setDateRange([{ startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate()), endDate: getEndOfDay(now), key: 'selection' }]);
        break;
      case 'thisWeek':
        setDateRange([{ startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7), endDate: getEndOfDay(now), key: 'selection' }]);
        break;
      case 'thisMonth':
        setDateRange([{ startDate: new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()), endDate: getEndOfDay(now), key: 'selection' }]);
        break;
      case 'custom':
        setShowCalendar(true);
        break;
      default:

      break;
    }
  }, [selectedDateRangeTab]);

  const handleDateChange = (ranges) => {
    setDateRangePreview([{
      ...ranges.selection,
      endDate: getEndOfDay(ranges.selection.endDate) // Set end date to the end of the selected day
    }]);
  };

  const handleCalendarClose = () => {
    setDateRange(dateRangePreview);
    setShowCalendar(false);
    setSelectedDateRangeTab('');
  };

  const sortedChatFeed = filteredChatFeed().sort((a, b) => {
    // Convert sentDate and sentTime to Date objects
    const dateTimeA = new Date(`${a.sentDate} ${a.sentTime}`);
    const dateTimeB = new Date(`${b.sentDate} ${b.sentTime}`);

    // Use getTime() to convert Dates to timestamps (numbers) before subtracting
    return dateTimeB.getTime() - dateTimeA.getTime();
  });

  // console.log(sortedChatFeed)
  return (

    <Grid container spacing={0} className='dashboard-section'>
  {!condition ? (
    errorAccess()
  ) : (
    <>

    <Grid item xs={12}  className='daterange-section'
      style={{paddingLeft: '16px', borderRadius: '1px', marginTop: '5px'}}>

    <Typography variant='subtitle1' className='table-title' sx={{ fontWeight:'bold',marginTop: '0px', }} >
          Date Range
        </Typography>
        <Tabs value={selectedDateRangeTab} onChange={(event, newValue) => setSelectedDateRangeTab(newValue)} aria-label="date range tabs"
        TabIndicatorProps={{ style: { height: 0 } }}
        variant="fullWidth"
        sx={{
          '.MuiTabs-flexContainer': {
            border: '1px solid #ddd',
            borderRadius: '5px',
            transition: 'all 300ms ease',
            width:'100%',
            overflow:'hidden',
            '.Mui-selected': {
              backgroundColor: '#009CDB',
              transition: 'all 300ms ease',
              color: 'white',
              },

              '.MuiTab-root': { // Targeting the Tab component
                minHeight: '30px', // Adjust this value as needed
                padding: '6px 10px', // Adjust padding as needed
                typography: 'body2',
                textTransform: 'none',
            },
          }
        }}>
          <Tab label="Today" value="today" />
          <Divider orientation="vertical" flexItem />
          <Tab label="This Week" value="thisWeek" />
          <Divider orientation="vertical" flexItem />
          <Tab label="This Month" value="thisMonth" />
          <Divider orientation="vertical" flexItem />
          <Tab label="Custom" value="custom" />

        </Tabs>
        {selectedDateRangeTab === 'custom' && showCalendar && (
                  <div className='calendar-popup'>
                    <Grid item className='calendar-popup' style={{ backgroundColor: 'transparent'}}>
                      <Grid item className='calendar-content' style={{ padding: '20px'}}>
                        <DateRange
                          ranges={dateRangePreview}
                          onChange={handleDateChange}
                          onRangeFocusChange={() => {}}
                          maxDate={currentDay}
                        />
                        <div className="button-container">
                          <Button id='button-calendar-submit' onClick={handleCalendarClose}>Submit</Button>
                          <Button id='button-calendar-close' onClick={() => {setShowCalendar(false); setSelectedDateRangeTab('today');}}>Close</Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='subtitle1' className='linechart-title'>

                          </Typography>
        </div>

        </Grid>


      {/* Chat Feed Section */}
      <Grid item md={9} sm={9} xs={9} className='chatfeed-section'
      style={{ paddingLeft: '16px', borderRadius: '1px', marginTop: '5px'}}>
        <Typography variant='subtitle1' className='table-title' sx={{ fontWeight: 'bold' }}>
          Chat Feed
        </Typography>
        <Tabs value={chatFeedFilter} onChange={handleFilterChange} aria-label="chat feed filter tabs"
        variant="fullWidth"
        TabIndicatorProps={{ style: { height: 0  } }}
        sx={{
          '.MuiTabs-flexContainer': {
            border: '1px solid #ddd',
            borderRadius: '5px',
            marginBottom: '14px',
            transition: 'all 300ms ease',
            overflow:'hidden',
            '.Mui-selected': {
              backgroundColor: '#009CDB',
              color: 'white',
              transition: 'all 300ms ease',
              },

              '.MuiTab-root': { // Targeting the Tab component
                minHeight: '30px', // Adjust this value as needed
                padding: '6px 12px', // Adjust padding as needed
                typography: 'body2',
                textTransform: 'none',
            },
          }
        }}>
          <Tab label="All" value="All"  />
          <Divider orientation="vertical" flexItem />
          <Tab label="Likes" value="Likes"/>
          <Divider orientation="vertical" flexItem />
          <Tab label="Dislikes" value="Dislikes"/>
          <Divider orientation="vertical" flexItem />
          <Tab label="Neither Like/Disliked" value="Neither"/>
        </Tabs>
        {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : (
        <div style={{
          maxHeight: 'calc(100vh - 200px)', // Adjust based on your header/nav/footer size
          overflowY: 'auto',
          paddingRight: '4px', // Prevents scrollbar from covering content
        }}>

{sortedChatFeed.length !== 0 ? (
          sortedChatFeed.map((item, index) => (

                <Box key={index} className={selectedRows.has(index) ? 'chat-row selected' : 'chat-row'} sx={{ textAlign: 'left' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center"
                  sx={{
                    backgroundColor: '#eeeeee',
                    padding: '5px',
                    borderRadius: '5px'
                    }}  >
                  <Box display='flex' alignItems='center'>
                    <Avatar alt={item.fullname} src={item.image} sx={{ width: 35, height: 35 }}/>
                    <Box>
                      <Typography variant="body2" component="div" sx={{ paddingLeft:'10px' }}>
                      <strong>{item.fullname}</strong>
                      </Typography>
                      <Typography variant="body2" component="div" sx={{ paddingLeft:'10px', color: '#7e7e7e' }}>
                      @{item.username}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'
                  sx={{
                    flexDirection: 'column',
                    textAlign: 'right',
                    }}>
                    <Typography variant="body2" component="div">
                      <strong>{item.sentDate}</strong>
                    </Typography>
                    <Typography variant="body2" component="div">
                      {item.sentTime}
                    </Typography>
                  </Box>
                </Box>

                {/* <Typography variant="body2" component="div" sx={{ paddingLeft:'10px', marginBottom:'3px' }}>
                  Prompt: {item.question || "N/A"}
                </Typography>

                <Typography variant="body2" component="div" sx={{ paddingLeft:'10px', marginBottom:'3px' }}>
                  <strong>Response:</strong> {item.message ? removeHTML(item.message): "N/A"}
                </Typography>

                {editingIndex === index ? (
                  <div style={{ paddingLeft:'10px', marginBottom:'3px' }}>
                    <strong>Edited Response:</strong>
                    <textarea
                      value={editedMessage}
                      onChange={(e) => setEditedMessage(e.target.value)}
                      style={{ width: '100%', font: 'inherit', padding: '10px' }}
                    />
                  </div>
                ) : (
                  <Typography variant="body2" component="div" sx={{ paddingLeft:'10px', marginBottom:'3px' }}>
                    <strong>Edited Response:</strong> {(item.editedMessage) ? removeHTML(item.editedMessage) : "N/A"}
                  </Typography>
                )}

                <Typography variant="body2" component="div" sx={{ paddingLeft:'10px', marginBottom:'3px' }}>
                  <strong>Feedback:</strong> {item.feedback || "None"}
                </Typography>

                <Typography variant="body2" component="div" sx={{ paddingLeft:'10px', marginBottom:'3px' }}>
                  <strong>Like/Dislike:</strong> {item.likeDislike || "None"}
                </Typography> */}

              <div style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: '10px',  padding: '10px', marginBottom: '3px', }}>
                <Typography variant="body2" component="div" sx ={{color: '#777777'}}>
                  Prompt:
                </Typography>
                <Typography variant="body2" component="div" sx ={{fontStyle: 'bold'}}>
                  {item.question || "N/A"}
                </Typography>

                <Typography variant="body2" component="div" sx ={{color: '#777777'}}>
                  Response:
                </Typography>
                <Typography variant="body2" component="div">
                  <MathJax dynamic hideUntilTypeset='every'>
                    <ReactMarkdown className={`markdown`}>{item.message ? removeHTML(item.message) : "N/A"}</ReactMarkdown>
                  </MathJax>
                </Typography>

                {editingIndex === index ? (
                  <>
                    <Typography variant="body2" component="div" sx ={{color: '#777777'}}>
                      Edited Response:
                    </Typography>
                    <div>
                      <TextareaAutosize
                        value={editedMessage}
                        onChange={(e) => setEditedMessage(e.target.value)}
                        style={{
                          width: '100%',
                          font: 'inherit',
                          padding: '10px',
                          resize: 'none',
                          backgroundColor: '#f8f8f8',
                          borderRadius: '5px',
                          border: 'none',
                          outline: 'solid 1px #c8c8c8'
                        }}
                      />
                      <Button
                        id='button-save'
                        variant="contained"
                        size="small"
                        onClick={() => handleSaveClick(index)}
                        sx={{
                          width: '12%',
                          margin: '8px 0',
                          borderRadius:'5px',
                        }} // Add some space around the buttons
                        >
                      Save
                    </Button>
                    <Button id='button-cancel-edit' className='button-cancel-edit' size="small" onClick={handleCancelClick}
                    sx= {{
                      width: '12%',
                      margin: '8px',
                      borderRadius: '5px',
                      bgcolor: '#8c8c8c',
                      color: '#ffffff',
                    }}>
                      Cancel
                    </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Typography variant="body2" component="div" sx ={{color: '#777777'}}>
                      Edited Response:
                    </Typography>
                    <Box>
                      <Typography variant="body2" component="div">
                        <MathJax dynamic hideUntilTypeset='every'>
                          <ReactMarkdown className={`markdown`}>{(item.editedMessage) ? removeHTML(item.editedMessage) : "N/A"}</ReactMarkdown>
                        </MathJax>
                      </Typography>
                      <Button
                        id='button-edit'
                        variant="contained"
                        size="small"
                        onClick={() => handleEditClick(index, (item.editedMessage ? removeHTML(item.editedMessage) : item.message), item.question)}
                        sx={{ margin: '8px 0',
                          borderRadius:'5px',
                        }} // Add some space around the buttons
                        >
                      Edit Response
                    </Button>
                    </Box>
                  </>
                )}
                <Typography variant="body2" component="div" sx ={{color: '#777777'}}>
                  Feedback:
                </Typography>
                <Typography variant="body2" component="div">
                  {item.feedback || "None"}
                </Typography>

                <Typography variant="body2" component="div" sx ={{color: '#777777'}}>
                  Like/Dislike:
                </Typography>
                <Typography variant="body2" component="div">
                  {item.likeDislike || "None"}
                </Typography>
              </div>
                </Box>
              ))
            ) : (
              <Typography variant="subtitle1" className="linechart-title" sx={{ textAlign: 'left' }}>
                No Data Available: AI Persona has not sent or received message
              </Typography>
            )}
            </div>
          )}
      </Grid>

      {/* Statistics Section */}
      <Grid item md={3} sm={3} xs={3} className='statistics-section'
      style={{paddingLeft: '16px', borderRadius: '4px',marginTop: '5px'}}>
        <Typography variant='subtitle1' className='linechart-title' sx={{ fontWeight: 'bold' }}>
          Statistics
        </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                  <Typography variant='body2' className='linechart-title' sx={{ color: 'black' }}>
                  Messages Sent & Recieved
                  </Typography>
                  <Typography variant='body2' style={{ marginLeft: 'auto',color: 'black' }}>
                    {totalMessages}
                  </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                  <Typography variant='body2' className='linechart-title' sx={{ color: 'black' }}>
                    Likes
                  </Typography>
                  <Typography variant='body2' style={{ marginLeft: 'auto',color: 'black' }}>
                    {chartData[0].count}
                  </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                  <Typography variant='body2' className='linechart-title' sx={{ color: 'black' }}>
                  Dislikes
                  </Typography>
                  <Typography variant='body2' style={{ marginLeft: 'auto',color: 'black' }}>
                    {chartData[1].count}
                  </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                  <Typography variant='body2' className='linechart-title' sx={{ color: 'black' }}>
              Total Messages Sent
            </Typography>
            <Typography variant='body2' style={{ marginLeft: 'auto',color: 'black' }}>
              {totalMessagesSentAndReceived}
            </Typography></div>
      </Grid>


    </>
  )}
</Grid>
  );
}
